













/**框架*/
import Vue from "vue"
/**工具库*/
import dayjs from "dayjs"
/**类型*/
/**组件*/
/**自定义方法*/

export default Vue.extend({
  name: "",
  props: {
    info: {
      default: {} as {
        id: string
        addr: string
        tickets: number
        bindAt: string
      },
    },
  },
  methods: {
    timeFormat(time: string) {
      return dayjs(Number(time) * 1000).format("MM/DD HH:mm:ss")
    },
    addFormat(addr: string) {
      return addr.slice(0, 6) + "......" + addr.slice(-5)
    },
  },
})
