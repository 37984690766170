import Rpc from '@33cn/chain33-rpc-api'
import { Toast } from 'vant';

export const rpc = new Rpc(process.env.VUE_APP_NODE,function(res:any){
  if(res.error){
    return Promise.reject(res.error);
  }
  return res.result
},function(err:any){
  Toast(err.message || err);
})