


















































































import Vue from "vue"
import MiningCard from "@/components/MiningCard.vue"
import xPopup from "@/components/Popup.vue"
import constant from "@/constance/Image"
import Bus from "@/utils/bus"
import { invitePool, poolList, checkBind, getTop } from "@/service/pool"
import service from "@/service/chain33"
import { PullRefresh, Toast, Popup, Dialog } from "vant"
import { mapState } from "vuex"

export default Vue.extend({
  components: {
    "van-pull-refresh": PullRefresh,
    "van-popup": Popup,
    "van-dialog": Dialog.Component,
    MiningCard,
    "x-popup": xPopup,
    "expired-popup": () => import("./expiredPopup.vue"),
  },
  data() {
    return {
      // 列表相关
      lists: [] as any,
      isLoading: false,
      refresh: false,
      page: 1,
      pageSize: 10,
      count: 0, // 总数
      coins: 0, // 计算余票
      timer: 0,
      waiter: 180000,
      // 数据展示，暂时隐藏
      showInfo: false,
      val: "", // 展示信息
      show: false, // 邀请人弹窗
      // 邀请确认弹窗
      showSure: false,
      text: "5",
      value: "",
      tipsTimer: 0,
      /**超过300天需求 */
      poolBinding: {},
      poolUnBindingStep: 0,
      showExpired: false,
      // showExpired: true,
    }
  },
  computed: {
    constant() {
      return constant.HOME_IMAGE
    },
    ...mapState({
      address: (state: any) => state.app.address,
    }),
  },
  watch: {
    /**检测lists，获取绑定的pool和是否过期 */
    async lists(val) {
      for (let item of val) {
        if (item.isBind && item.leftTime <= 0) {
          this.poolBinding = item
          const response = await service.getTicketAddress(this.address)
          if (!response.data.result) {
            this.poolUnBindingStep = 1
          }
          this.showExpired = true
          break
        }
      }
    },
  },
  methods: {
    getCoins() {
      service.getBalance(this.address, "coins").then((res: any) => {
        this.coins = Math.floor(res.data.result[0].balance / 1e8)
      })
    },
    showTheInfo(val: string) {
      this.showInfo = true
      this.val = val
    },
    getList() {
      poolList({
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((res: any) => {
          Toast.clear()
          if (this.refresh) {
            this.refresh = false
            this.lists = []
          }
          const { data } = res.data
          data?.list ? this.lists.push(...data?.list) : (this.lists = [])
          this.count = +data.count
          document.addEventListener("scroll", this.scrollEvent)
          console.log(res.data)
        })
        .catch((e) => {
          Toast.clear()
          const err = e.response
          if (err.data?.code === 406 && err.data?.reason === "USER_NOT_BIND") {
            this.show = true
          } else if (err.data?.code === 413 || err.data?.code === 403) {
            Toast.loading({
              message: err.data.message,
              duration: 0,
              forbidClick: true,
            })
          } else {
            Toast(err.data.message)
          }
          // 报错不需要重复请求
          if (this.timer) {
            clearInterval(this.timer)
          }
        })
      this.isLoading = false
    },
    // 初始化
    onRefresh() {
      this.page = 1
      this.refresh = true
      this.getList()
    },
    scrollEvent() {
      const e = document.querySelector(".mining-card")
      const g = (e as HTMLElement)?.offsetHeight
      const h = document.documentElement?.scrollTop || document.body.scrollTop
      if (this.lists.length < this.count) {
        if (h > g * this.page * (this.pageSize - 3)) {
          this.page++
          this.getList()
        }
      }
    },
    setTimer() {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.onRefresh()
      }, this.waiter)
    },
    goDetails(val: {
      id: string
      minerAddr: string
      sellDays: string
      startTime: string
    }) {
      service
        .getTicketAddress(this.address)
        .then((r) => {
          const addr = r.data.result?.data
          // 已经投过票，跳过封闭期
          if (addr) {
            if (addr !== val.minerAddr) {
              Toast("该账号已绑定过票池")
            } else {
              Toast.clear()
              this.$router.push({
                path: "/details",
                query: { id: val.id || "1" },
              })
            }
            // checkBind(val.id).then(res => {
            //   if (res.data.data.isSucc) {
            //     Toast.clear()
            //     this.$router.push({ path: '/details', query: { id: val.id || '1' }})
            //   }
            // }).catch(() => {
            //   Toast('该账号已绑定过票池')
            // })
          } else {
            // 未投过，检测封闭期是否到期，到期停止进入
            const _now = Date.now()
            const endTime = (+val.startTime + +val.sellDays * 24 * 3600) * 1000
            if (endTime < _now && val.sellDays !== "0") {
              Toast("该票池已过封闭期，您不能进入")
            } else {
              // 清除失败提示
              Toast.clear()
              this.$router.push({
                path: "/details",
                query: { id: val.id || "1" },
              })
            }
          }
        })
        .catch(() => {
          Toast("网络异常")
        })
    },
    addInvite(val: string) {
      if (!val) {
        Toast("请先输入推荐人地址")
        return
      } else {
        getTop(val).then((res) => {
          console.log("res-isTop", res)
          // 超级节点|社区节点直接推荐
          if (res.data.data.isSucc) {
            this.value = val
            this.text = "5"
            if (this.tipsTimer) clearInterval(this.tipsTimer)
            this.tipsTimer = setInterval(() => {
              this.text = +this.text > 0 ? +this.text - 1 + "" : "确定"
            }, 1000)
            this.showSure = true
          } else {
            // 查询冻结余额， > 3000 可推荐
            service.getBalance(val, "ticket").then((res: any) => {
              const restFreeze = Math.floor(res.data.result[0].frozen / 1e8)
              console.log(
                res.data.result[0].frozen,
                "冻结余额--ticket",
                restFreeze
              )
              if (restFreeze >= 3000) {
                this.value = val
                this.text = "5"
                if (this.tipsTimer) clearInterval(this.tipsTimer)
                this.tipsTimer = setInterval(() => {
                  this.text = +this.text > 0 ? +this.text - 1 + "" : "确定"
                }, 1000)
                this.showSure = true
              } else {
                Toast("该推荐人暂无推荐资格")
              }
            })
          }
        })
      }
    },
    handleConfirm() {
      if (+this.text > 0) {
        this.showSure = true
        return
      }
      console.log("this.val", this.value)
      invitePool(this.value)
        .then((res: any) => {
          if (res.data.data.isSucc) {
            Toast("添加成功")
            this.show = false
            this.showSure = false
            this.getList()
            Bus.$emit("updateMsg", true)
          } else {
            Toast("请输入正确的推荐人地址")
          }
        })
        .catch((e) => {
          console.log(e)
          const { status } = e.response
          if (status === 413) {
            Toast("该设备已绑定过推荐人")
          } else {
            Toast("请输入正确的推荐人地址")
          }
          this.showSure = false
        })
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCoins()
      this.getList()
      this.setTimer()
    }, 500)
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer)
  },
})
