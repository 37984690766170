






































import Vue from "vue";
import LockStatusDialog from "@/components/LockStatusDialog.vue";
import InputPasswordDialog from "@/components/InputPasswordDialog.vue";
import WalletStatus from "@/components/WalletStatus.vue";
import { getNodeStatus } from "@/utils/getDeviceInfo";
import { rpc } from "@/service/rpc";
import { Toast } from "vant";
import service from '@/service/chain33';

enum MineStatus2 {
  StartMining, // 未挖矿 -开始挖矿中
  EndMining, // 挖矿 - 结束挖矿中
  MineOrUnMine //未挖矿/挖矿
}
const MAX_BUY_TICKET = 2 * 60;
const MAX_CLOSE_TICKET = 48 * 60 * 60; // 100小时 * 60分钟 * 60s
const MinerStatus2Rsolving = [MineStatus2.StartMining,MineStatus2.EndMining]

export default Vue.extend({
  components: {
    WalletStatus,
    LockStatusDialog,
    InputPasswordDialog,
  },
  data() {
    return {
      walletStatus: {} as any,
      passwordDialogShow: false,
      errMsg: "",
      lockStatusDialogShow: false,
      showAction: false,
      isSync: false,
      ticketCount: 0,
      peersInfo: {} as any,
      accounts: {} as any,
      password: "",
      userSelectedStatus: "",
      timer: 0,
      isMiner: false,
      hasFee: false,
      canBuyTicket: false,
      isAuorizeTicket: false,
      minerStatus2: MineStatus2.MineOrUnMine,
      count: 0,
      ticketInfo: {} as any,
      countDownTimer: 0
    };
  },
  computed: {
    ip(): string {
      console.log(this.$route.params.ip);
      return this.$route.params.ip || "";
    },
    url(): string {
      return this.ip ? `http://${this.ip}:8801` : "";
    },
    peerInfo(): any {
      return (
        (this.peersInfo &&
          this.peersInfo.peers &&
          this.peersInfo.peers.find((peer: any) => {
            return peer.self;
          })) ||
        {}
      );
    },
    peerHeight(): number {
      return (this.peerInfo.header && this.peerInfo.header.height) || 0;
    },
    allHeight(): number {
      return (
        (this.peersInfo.peers &&
          this.peersInfo.peers.reduce(
            (acc: number, cur: any) => Math.max(acc, cur.header.height),
            0
          )) ||
        0
      );
    },
    blockSync(): boolean {
      return this.peerHeight === this.allHeight;
    },
    paiWalletInfo(): any {
      return (
        (this.accounts &&
          this.accounts.wallets &&
          this.accounts.wallets.find((wallet: any) => {
            return wallet.label === "YUAN-miner节点";
          })) ||
        {}
      );
    },
    walletLabel(): string {
      return this.paiWalletInfo.label || "";
    },
    paiWalletAddress(): string {
      return (this.paiWalletInfo.acc && this.paiWalletInfo.acc.addr) || "";
    },
    // canBuyTicket(): boolean {
    //   return this.coinsExecBalance.balance / 1e8 >= 500;
    // },
    // hasFee():boolean{
    //   return this.coinsExecBalance.balance / 1e8 >= 1;
    // }
    ticketInfoAndMinerStatus(): any {
      return { ticketInfo: this.ticketInfo, ticketCount: this.ticketCount };
    },
    isLockWallet():boolean {  // 钱包是否锁定 true:锁定整个钱包 false:没有锁定整个钱包
      const {isWalletLock,isTicketLock} = this.walletStatus;
      return isWalletLock?isTicketLock:false
    },
    isShowMiningBtn(): boolean {
      return this.blockSync && !this.isLockWallet;
    },
    miningBtnStr(): string {
      const {isMiner,ticketCount} = this;
      if (this.minerStatus2 === MineStatus2.StartMining) {
        return `挖矿准备中`;
      }
      if (this.minerStatus2 === MineStatus2.EndMining) {
        return `挖矿结束中`;
      }

      if (isMiner) {
        return '结束挖矿';
      } else if(ticketCount <= 0){
        return '开始挖矿';
      } else {
        return '取回';
      }
    }
  },
  created() {
    this.init();
    service.getBalance(this.$store.state.app.address, 'coins').then((res: any) => {
      const count = Math.floor(res.data.result[0].balance / 1e8)
      this.hasFee = count >= 1
      this.canBuyTicket = count >= 500
    })
    this.timer = setInterval(() => {
      let { getTicketCount, getPeerInfo } = rpc;
      const { url } = this;
      let promise = [getTicketCount.call(rpc, url), getPeerInfo.call(rpc, url)];
      Promise.all(promise).then(([ticketCount, peersInfo]) => {
        this.ticketCount = ticketCount;
        this.peersInfo = peersInfo;
      });
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async init() {
      const { walletStatus, isSync, ticketCount, peersInfo, accounts } =
        await getNodeStatus({ ip: this.ip });
      this.walletStatus = walletStatus;
      this.isSync = isSync;
      this.ticketCount = ticketCount;
      this.peersInfo = peersInfo;
      this.accounts = accounts;
      const { paiWalletAddress, url } = this;
      if (!this.walletStatus.isAutoMining) {
        const { msg } = await rpc.setAutoMining(1, url);
        if (msg) throw msg;
      }
    },
    // 开始挖矿结束挖矿处理
    async miningHandler() {
      //购票中，或者结束挖矿中禁止点击
      if (MinerStatus2Rsolving.includes(this.minerStatus2)) {
        // Toast("当前状态禁止操作自动购票");
        return;
      }
      const { url } = this;
      if (this.isMiner) {
        this.closeMining();
      } else if (this.ticketCount <= 0) {
        this.openMining();
      } else {
        this.withDrawTicket();
      }
    },
    async withDrawTicket() {
      const { url } = this;
      this.$toast.loading({
        message: "取回中...",
        forbidClick: true,
        duration: 0,
      });
      await service.closeTicket(url);
      this.init();
      this.$toast.success("取回成功");
    },
    //开始挖矿
    openMining() {
      //区块未同步完成
      if (!this.blockSync) {
        this.$toast("区块未同步完成,请先等待节点区块同步完成");
        return;
      }
      //余额太多不允许开启挖矿--限制自己挖矿，鼓励委托挖矿
      //TODO  没有手续费不允许开始挖矿
      if (this.canBuyTicket) {
        this.$toast("当前钱包为被委托挖矿钱包，仅存放挖矿手续费即可");
        return;
      }
      if (!this.hasFee) {
        this.$toast("请确保有充足的手续费(至少一个bty)");
        return;
      }

      if (!this.isAuorizeTicket) {
        this.$toast("您当前没有被委托票数");
        return;
      }

      this.$dialog
        .confirm({
          title: "开始挖矿确认",
          message: "是否开始挖矿？", //将为您开启自动购票功能，挖矿中您可以关闭自动购票功能
        })
        .then(async () => {
          const { url } = this;
          // await minerTime(this.paiWalletAddress, "start");
          const { msg } = await rpc.setAutoMining(1, url);
          if (msg) throw msg;
          this.minerStatus2 = MineStatus2.StartMining;
          this.count = MAX_BUY_TICKET;
          this.init();
          // this.walletStatus = await this.$api.getWalletStatus(url);
        })
        .catch((err) => {
          this.$toast(err.message || err);
        });
    },
    //结束挖矿
    closeMining() {
      this.$dialog
        .confirm({
          title: "结束挖矿确认",
          message: "是否结束挖矿?\n结束挖矿会持续最大48个小时",
        })
        .then(async () => {
          // await minerTime(this.paiWalletAddress, "end");
          const { msg } = await rpc.setAutoMining(0, this.url);
          if (msg) throw msg;

          this.walletStatus = await rpc.getWalletStatus(this.url);
          await service.closeTicket(this.url);
          this.minerStatus2 = MineStatus2.EndMining;
          this.count = MAX_CLOSE_TICKET;
        })
        .catch((err) => {
          if (err !== "cancel") {
            this.$toast(err.message || err);
          }
        });
    },
    reCheckLockStatusHandler() {
      this.passwordDialogShow = false;
      this.lockStatusDialogShow = true;
    },
    async confirmPasswordHandler(password: string) {
      this.errMsg = "";
      this.password = password;
      if (this.password === "") {
        this.errMsg = "请输入密码";
        return;
      }
      try {
        let flag = true; // true : 解锁购票 ；false : 解锁整个钱包
        if (this.userSelectedStatus === "1") {
          flag = false;
        }
        const { msg } = await rpc.unlock(this.password, flag, 0, this.url);
        if (msg) {
          if (["ErrVerifyOldpasswdFail", "ErrInputPassword"].includes(msg)) {
            this.errMsg = "请输入正确的密码";
            return;
          } else {
            throw msg;
          }
        }
        await rpc.lock(this.url);
        await rpc.unlock(this.password, flag, 0, this.url);
      } catch (err: any) {
        Toast(err.message || err);
      } finally {
        this.init();
      }
    },
    showPasswordDialogHandler(status: string) {
      console.log("userSelected status:", status);
      this.userSelectedStatus = status;
      this.passwordDialogShow = true;
      this.lockStatusDialogShow = false;
      // this.hideLockStatusDialog();
      // this.showPasswordDialog();
    },
    clearCountDownTimer(){
      if(this.countDownTimer){
        clearInterval(this.countDownTimer);
        this.countDownTimer = 0;
      }
    }
  },
  watch: {
    ticketInfoAndMinerStatus: {
      deep: true,
      immediate: true,
      handler: function (ticketInfoAndMinerStatus: any, newVal: any) {
        console.log('ticketInfoAndMinerStatus', ticketInfoAndMinerStatus);
        let {
          serve_time: serverTime,
          miner_start_time: minerStartTime,
          miner_end_time: minerEndTime
        } = ticketInfoAndMinerStatus.ticketInfo;
        let diffStartTime = serverTime - minerStartTime;
        let diffEndTime = serverTime - minerEndTime;
        console.log(diffStartTime, diffEndTime, serverTime);
        if (diffStartTime < MAX_BUY_TICKET && this.walletStatus.isAutoMining) {
          this.minerStatus2 = MineStatus2.StartMining;
          this.count = MAX_BUY_TICKET - diffStartTime;
        } else if (
          diffEndTime < MAX_CLOSE_TICKET &&
          !this.walletStatus.isAutoMining &&
          ticketInfoAndMinerStatus.ticketCount > 0
          // ticketInfoAndMinerStatus.minerStatus === MineStatus.Mine
        ) {
          this.minerStatus2 = MineStatus2.EndMining;
          this.count = MAX_CLOSE_TICKET - diffEndTime;
        } else {
          this.minerStatus2 = MineStatus2.MineOrUnMine;
        }
        console.log('this.minerStatus2', this.minerStatus2);
      }
    },
    count: {
      handler: async function (count: number, oldVal: number) {
        if (count > 0) {
          if (!this.countDownTimer) {
            this.countDownTimer = setInterval(async () => {
              this.count--;   
            }, 1000);
          }
        } else {
          this.minerStatus2 = MineStatus2.MineOrUnMine;
          this.clearCountDownTimer();      
        }
      }
    }
  }
});
