











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import { Dialog, Field } from 'vant';

@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    'van-field': Field
  }
})
export default class InputPasswordDialog extends Vue {
  @PropSync('showPasswordDialog', { type: Boolean }) show!: boolean;
  // @PropSync("errMsg",{type:String}) errMsgself!:string
  @Prop({
    type: String,
    required: true
  })
  errMsg!: string;
  password = '';

  cancelHandler() {
    this.password = '';
    this.$emit('reCheckLockStatus');
  }
  confirmPasswordHandler(action: string, done: any) {
    this.$emit('confirmPassword', this.password);
    setTimeout(() => {
      this.$nextTick(() => {
        if (!this.errMsg) {
          this.password = ''
          done();
        } else {
          done(false);
        }
      });
    }, 1000);
  }
}
