import {importSeed} from '@/utils/bridge'
export function passwdInputProxy(fn:Function){
    return new Proxy(fn,{
        async apply(target,thisBind,args){
            try{
                importSeed('');
            }catch(err){
                if(err === '取消支付'){
                    throw '取消'
                }
                throw err;
            }
            return Reflect.apply(target,thisBind,args)
        }
    })
}