














import Vue from 'vue'
export default Vue.extend({
  props: {
    info: {
      type: Object
    },
    bgColor: {
      type: String,
      default: 'bg-gradient-7'
    },
    color: {
      type: String,
      default: 'text-brown3'
    },
    borderColor: {
      type: String,
      default: 'border-card2-color'
    }
  }
})
