import { request } from "./service";
import store from '@/store/index'
import { AxiosResponse, AxiosRequestConfig } from "axios";

const service = request(`/air`)

service.interceptors.request.use(function (config: AxiosRequestConfig) {
  if ((store.state as any).app.address) {
    const { address } = (store.state as any).app
    config.headers['Auth-Addr'] = address
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

service.interceptors.response.use(function (res: AxiosResponse) {
  return res.data
}, function(error) {
  return Promise.reject(error)
})

export const getMinerData = (data: {page: number, pageSize: number, month: string}) => {
  return service.get('/miner-data', { params: data})
}