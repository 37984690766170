













































import Vue from "vue"
import constant from "@/constance/Image"
export default Vue.extend({
  props: {
    type: {
      type: Number,
    },
    info: {
      type: Object,
    },
  },
  computed: {
    constant() {
      return constant.MINE_IMAGE
    },
  },
})
