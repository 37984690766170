

































import Vue from "vue"
import service from "@/service/chain33"
import { getUserInfo } from "@/service/pool"
import { TICKET_COUNT, TICKET_ADDRESS } from "@/constance/const"
import { Toast } from "vant"
import Bus from "@/utils/bus"
import { mapState } from "vuex"
export default Vue.extend({
  data() {
    return {
      addr: "", // 当前地址
      inviter: "", // 推荐人地址
      profit: 0, // 当前收益
      ticketNum: 0, // 当前票数
      btnText: "领取奖励",
      fee: 0,
      timer: 0,
      waiter: 1000,
    }
  },
  computed: {
    ...mapState({
      address: (state: any) => state.app.address,
    }),
  },
  methods: {
    // freezon 前重复请求，已取消
    waitToPool() {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.getInfo()
      }, 5000)
    },
    getInfo() {
      service.getBalance(this.address, "ticket").then((res: any) => {
        const { result } = res.data
        console.log("用户余额", result[0].frozen)
        this.profit = Math.floor(result[0].balance / 1e8)
        this.ticketNum = Math.floor(result[0].frozen / 1e8 / TICKET_COUNT)
        // this.profit = Math.floor((res.data.result as any)[0].balance / 1e8)
        // this.ticketNum = Math.floor((res.data.result as any)[0].frozen / 1e8 / TICKET_COUNT)
        if (this.timer) clearInterval(this.timer)
      })
      getUserInfo().then((res) => {
        console.log("inviter", res.data.data.inviter)
        this.inviter = res.data.data.inviter
      })
    },
    // 手续费
    getFee() {
      service.getProperFee(0, 0).then((res) => {
        this.fee = res.data.result.properFee
      })
    },
    async getIncome() {
      if (!this.profit) {
        Toast("当前无奖励可领")
        return
      }
      Toast.loading({
        message: "领取中",
        forbidClick: true,
        duration: 0,
      })
      const transaction = await service.createTransaction({
        to: TICKET_ADDRESS,
        amount: this.profit * 1e8,
        fee: this.fee,
        note: "ticket -> coins",
        isWithdraw: true,
        execName: "ticket",
      })
      service.signTransition(
        (transaction as any).data.result,
        async (sign: string | Object) => {
          console.log("sign------------", sign)
          const s = typeof sign === "string" ? JSON.parse(sign) : sign
          if (s.error) {
            Toast.fail(s.error)
            return
          }
          const send = await service.sendTransaction(s.signHash)
          if (send.data.error) {
            Toast(send.data.error)
            return
          }
          let res = {} as any
          const timer = setInterval(async () => {
            res = await service.getTransaction(send.data.result)
            if (!res.data.error) {
              clearInterval(timer)
              if (res.data.result.receipt.ty === 1) {
                Toast.fail({
                  message: "领取失败",
                  duration: 3000,
                })
              } else {
                Toast.success({
                  message: "领取成功",
                  duration: 3000,
                })
                this.getInfo()
              }
            }
          }, this.waiter)
        }
      )
    },
  },
  created() {
    console.log("address--info", this.address)
    console.log("每票所含的数值", TICKET_COUNT)
    this.addr = this.address
    this.addr = this.addr.slice(0, 8) + "..." + this.addr.slice(-8)
    this.getInfo()
    this.getFee()
    Bus.$on("updateMsg", () => {
      this.getInfo()
    })
  },
})
