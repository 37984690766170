import { callDeviceManagerAPI } from '@/service/service';

/**
 * @description  重置钱包
 * @param ip  
 */
export  function resetWallet(ip = ''){
  return callDeviceManagerAPI({

  },'resetwallet',ip);
}

/**
 * @description  节点是否在升级
 * @param ip  
 */
export async function nodeUpdate(ip = ''){
  const {status} = await callDeviceManagerAPI({
  },'nodeupdate',ip);
  if(status){
    throw '正在升级'
  }
}

/**
 * @description  安全关闭节点
 * @param ip  
 */
export  function closeNode(ip = ''){
  return callDeviceManagerAPI({

  },'closenode',ip);
}

/**
 * @description 重置节点
 * @param ip 
 */
export  function reset(ip = ''){
  return callDeviceManagerAPI({
  },'reset',ip);
}



/**
 * @description 查询节点版本
 * @param ip 
 */
export  function version(ip = ''){
  return callDeviceManagerAPI({
  },'version',ip);
}

/**
 * @description 查询守护程序版本
 * @param ip 
 */
export  function paiVersion(ip = ''){
  return callDeviceManagerAPI({
  },'paiversion',ip);
}

/**
 * @description 更新守护进程
 * @param version
 * @param ip 
 */
export  function updatePai(version = '' ,ip = ''){
  return callDeviceManagerAPI({
      version
  },'updatepai',ip);
}

/**
 * @description 节点更新到新版本
 * @param ip 
 */
export  function updateNode(ip = ''){
  return callDeviceManagerAPI({
  },'updatenode',ip);
}

export  function updateDetail(ip = ''){
  return callDeviceManagerAPI({
  },'updatedetail',ip);
}


export function downloadpercent(ip = ''){
  return callDeviceManagerAPI({

  },'dpercent',ip)
}

export function nodeInfo(ip = ''){
  return callDeviceManagerAPI({

  },'nodeinfo',ip)
}

export function serverstatus(ip = ''){
  return callDeviceManagerAPI({},'serverstatus',ip)
}


/**
 * 代解锁
 * @param password  钱包密码
 * @param ip 节点ip
 */
export function escrowPassword(password:string,ip = ''){
  return callDeviceManagerAPI({password},'escrowpassword',ip)
}

/**
 * 反馈接口
 * @param description 
 * @param ip 
 */
export function feedback(description:string,addr:string,ip = ''){
  return callDeviceManagerAPI({description,addr},'feedback',ip)
}

/**
 * 最新反馈是否完成查询接口
 * @param ip 
 */
export function isComplete(ip = ''){
  return callDeviceManagerAPI({},'iscomplete',ip);
}


