




















import Vue from 'vue'
import Header from '@/components/Header.vue'
import InfoCard from '@/views/Home/InfoCard.vue'
import PoolList from '@/views/Home/PoolList.vue'
import { Icon, Toast } from 'vant'
export default Vue.extend({
  components: {
    Header,
    'van-icon': Icon,
    InfoCard,
    PoolList
  },
  methods: {
    goMining() {
      this.$router.push({ path: '/mine', query: {}})
    },
  },
  mounted() {
    Toast.loading({
      duration: 0,
      forbidClick: true
    })
  }
})
