


















import Vue from 'vue'
import MiningInfo from '@/components/MiningInfo.vue'
import { PullRefresh } from 'vant'
import { userMachineList } from '@/service/pool'
export default Vue.extend({
  components: {
    MiningInfo,
    'van-pull-refresh': PullRefresh
  },
  props: {
    active: {
      type: [Number, String],
      default: 1
    }
  },
  data() {
    return {
      lists: [] as any,
      isLoading: false,
      page: 1,
      pageSize: 20,
      count: 0,
    }
  },
  methods: {
    getNowList() {
      userMachineList({
        page: this.page,
        pageSize: this.pageSize,
        status: 2
      }).then(res => {
        this.count = +res.data.data.count
        this.lists = res.data.data.list || []
        document.addEventListener('scroll', this.scrollEvent)
      })
      this.isLoading = false
    },
    getEndList() {
      userMachineList({
        page: this.page,
        pageSize: this.pageSize,
        status: 1
      }).then(res => {
        this.count = +res.data.data.count
        this.lists = res.data.data.list || []
        document.addEventListener('scroll', this.scrollEvent)
      })
      this.isLoading = false
    },
    scrollEvent() {
      const e = document.querySelector('.mining-card')
      const g = (e as HTMLElement)?.offsetHeight
      const h = document.documentElement?.scrollTop || document.body.scrollTop
      if (this.lists.length < this.count) {
        if (h > (g * this.page * (this.pageSize - 3))) {
          this.page++
          if (this.active === 1) {
            this.getNowList()
          } else {
            this.getEndList()
          }
        }
      }
    },
    onRefresh() {
      this.page = 1
      this.pageSize = 20
      if (this.active === 1) {
        this.getNowList()
      } else {
        this.getEndList()
      }
    },
  },
  created() {
    this.getNowList()
  },
  watch: {
    active(n) {
      this.lists = []
      if (n === 1) {
        this.getNowList()
      } else {
        this.getEndList()
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollEvent)
  }
})
