























































import Vue, { PropType } from 'vue';
import WalletStatus from '@/components/WalletStatus.vue';
interface IDeviceInfo {
  serial: string; //设备唯一序列号
  ip: string; //树莓派设备局域网ip
  isBind: boolean;
  ticketCount: any;
  accounts: any;
  isSync: any;
  walletStatus: any;
  peersInfo: any;
  err:any;
}
export default Vue.extend({
  props: {
    deviceInfo: {
      type: Object as PropType<IDeviceInfo>,
      required: true
    },
    isfeedback: {
      type: Boolean,
      required: false,
      default:false
    }
  },
  components: {
    WalletStatus
  },
  data() {
    return {
      showMenu: false,
      err: '',
      isBind: false,
      ticketCount: '' as any,
      accounts: {} as any,
      isSync: '' as any,
      walletStatus: '' as any,
      peersInfo: '' as any,
    }
  },
  computed: {
    isMining(): boolean{
      return !this.isLockWallet && this.miningBtyCount > 0  && this.blockSync
    },
    isLockWallet():boolean{  // 钱包是否锁定 true:锁定整个钱包 false:没有锁定整个钱包
      const {isWalletLock,isTicketLock} = this.walletStatus;
      return isWalletLock?isTicketLock:false
    },
    paiWalletAddress():string {
      return (
        (this.paiWalletInfo.acc &&
          this.paiWalletInfo.acc.addr) ||
        ''
      );
    },
    deviceSerial(): string {
      return this.deviceInfo.serial || '';
    },
    miningStatusStr(): string {
      if (this.isMining) {
        return '正在挖矿';
      } else {
        return '未挖矿';
      }
    },
    shortAddress():string {
      if (this.paiWalletAddress) {
        return this.paiWalletAddress.slice(0, 8) + '...' + this.paiWalletAddress.slice(-8)
      } else {
        return ''
      }
    },
    miningBtyCount(): number {
      return this.deviceInfo.ticketCount * 3000;
    },
    peerInfo(): any {
      return (
        (this.peersInfo && this.peersInfo.peers &&
          this.peersInfo.peers.find((peer: any) => {
            return peer.self;
          })) ||
        {}
      );
    },
    peerHeight(): number {
      return (this.peerInfo.header && this.peerInfo.header.height) || 0;
    },
    allHeight(): number {
      return (
        (this.peersInfo.peers &&
          this.peersInfo.peers.reduce(
            (acc: number, cur: any) => Math.max(acc, cur.header.height),
            0
          )) ||
        0
      );
    },
    blockSync(): boolean {
      return this.peerHeight === this.allHeight;
    },
    paiWalletInfo():any {
      return (
        this.accounts && this.accounts.wallets &&
        this.accounts.wallets.find((wallet: any) => {
          return wallet.label === 'YUAN-miner节点'
        }) || {}
      )
    },
    walletLabel():string {
      return this.paiWalletInfo.label || ''
    }
  },
  methods: {
    resetWallet() {
      this.$emit('reset-wallet',{
        ip: this.deviceInfo.ip,
        mineAddress: this.paiWalletAddress,
        deviceSerial: this.deviceSerial,
      })
    },
    safeClose() {
      this.$emit('safe-close', {
        ip: this.deviceInfo.ip,
        mineAddress: this.paiWalletAddress,
      })
    },
    feedback() {
      console.log({
        ip: this.deviceInfo.ip,
        mineAddress: this.paiWalletAddress,
        deviceSerial: this.deviceSerial,
      })
      this.$emit('feed-back', {
        ip: this.deviceInfo.ip,
        mineAddress: this.paiWalletAddress,
        deviceSerial: this.deviceSerial,
      })
    },
    showMenuHandler(){
      this.showMenu = !this.showMenu;
    },
    link() {
      this.$emit('link-device', {
        ip: this.deviceInfo.ip,
        deviceSerial: this.deviceSerial
      })
    },
    routeToMiningDetailHandler() {
      if (this.showMenu) {
        this.showMenu = false
      }
      this.$emit('route-to', {
        ip: this.deviceInfo.ip,
        mineAddress: this.paiWalletAddress,
        deviceSerial:this.deviceSerial,
      })
    }
  },
  watch: {
    'deviceInfo': {
      immediate: true,
      deep: true,
      handler(val: IDeviceInfo, oldVal: IDeviceInfo) {
        this.isBind = val.isBind;
        this.walletStatus = val.walletStatus;
        this.isSync = val.isSync;
        this.ticketCount = val.ticketCount;
        this.peersInfo = val.peersInfo;
        this.accounts = val.accounts;
        this.err = val.err;
      }
    }
  }
})
