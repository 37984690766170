











import Vue from 'vue'
import { USER_TYPE } from '@/constance/const';
export default Vue.extend({
  data() {
    return {
      msg: '',
      state: 0
    }
  },
  mounted() {
    const status = this.$route.query.status
    const type:number = +this.$route.query.type - 1
    this.msg = USER_TYPE[type]
    if (status) {
      this.state = 0
    } else if (type < 2) {
      this.state = 1
      this.msg = USER_TYPE[type]
    } else {
      this.state = 2
    }
  }
})
