





















































import Vue from "vue";
import InfoBar from "@/components/InfoBar.vue";
import { TICKET_COUNT } from "@/constance/const";
// import TimeBox from './TimeBox.vue'
// import Popup from '@/components/Popup.vue';
export default Vue.extend({
  props: {
    info: {
      type: [Object as any, Number],
    },
    coin: {
      type: Number,
      default: 0,
    },
  },
  components: {
    InfoBar,
    // 'van-popup': Popup
    // TimeBox
  },
  data() {
    return {
      show: true,
      prefix: "",
    };
  },
  methods: {
    goMining() {
      this.$emit("goDetails", this.info as any);
    },
    showInfo() {
      this.$emit("showInfo", (this.info as any).dayRate);
    },
  },
  computed: {
    runDays() {
      const info = (this as any).info;
      const isSell = +info.sellDays;
      if (isSell) {
        (this as any).prefix = "封闭期";
        return isSell * 24 * 3600 + Number(info.startTime) + "";
      } else {
        return Number(info.startTime) + +info.days * 3600 * 24 + "";
      }
    },
    rest() {
      return Math.floor((this.coin - 1) / TICKET_COUNT) < 0
        ? 0
        : Math.floor((this.coin - 1) / TICKET_COUNT);
    },
    leftTime() {
      return (this as any).info.isBind
        ? "剩余时间：" +
            Math.ceil((this as any).info.leftTime / 3600 / 24) +
            "天"
        : " ";
    },
  },
});
