













import Vue from 'vue'
import { Popup, Icon } from 'vant';
import { getScan } from '../utils/bridge';
export default Vue.extend({
  components: {
    'van-popup': Popup,
    'van-icon': Icon
  },
  data() {
    return {
      val: ''
    }
  },
  props: {
    title: {
      type: String,
      default: '请输入推荐人'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submit() {
      this.$emit('getMessage', this.val)
    },
    scan() {
      console.log('123')
      getScan('', (v: string) => {
        // this.$emit('getMessage', v)
        this.val = v
      })
    }
  }
})
