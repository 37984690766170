














import Vue from 'vue'
import { NavBar } from 'vant'
import MachineList from '@/views/Mine/MachineList.vue'
export default Vue.extend({
  components: {
    'nav-bar': NavBar,
    MachineList
  },
  data() {
    return {
      active: 1,
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    setTab(n: number) {
      this.active = n
    }
  },
})
