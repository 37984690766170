

const HOME_IMAGE = {
  TITLE: require('@/assets/image/title.png'),
  TITLE_LEFT: require('@/assets/image/title-l.png'),
  TITLE_RIGHT: require('@/assets/image/title-r.png'),
  CARD: require('@/assets/image/card.png')
}

const MINE_IMAGE = {
  BG_START: require('@/assets/image/bg1-card.png'),
  BG_END: require('@/assets/image/bg2-card.png'),
  END: require('@/assets/image/end.png')
}

export default {
  HOME_IMAGE,
  MINE_IMAGE
}