

















import Vue from "vue";
import { Stepper } from "vant";
export default Vue.extend({
  components: {
    "van-stepper": Stepper,
  },
  props: {
    leftText: {
      type: String,
      default: "票数",
    },
    btnText: {
      type: String,
      default: "委托",
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    buy() {
      this.$emit("buy", this.value);
    },
  },
});
