











import Vue from 'vue'
export default Vue.extend({
  props: {
    height: {
      type: String,
      default: ''
    }
  }
})
