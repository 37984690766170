import { rpc } from "@/service/rpc";
import { nodeUpdate } from "@/service/device";

export const getNodeStatus = async (deviceInfo: any) => {
  console.log("deviceInfo", deviceInfo);
  const { ip } = deviceInfo;
  const url = `http://${ip}:8801`;
  const nodeStatus = { isBind: false, ...deviceInfo };
  try {
    await nodeUpdate(ip);
    const allResult: any = await Promise.all([
      rpc.getWalletStatus(url),
      rpc.isSync(url),
      rpc.getTicketCount(url),
      rpc.getAccounts(false, url),
      rpc.getPeerInfo(url),
    ]);
    const [walletStatus, isSync, ticketCount, accounts, peersInfo] = allResult;
    console.log(walletStatus, "walletStatus-------------", allResult);
    if (!walletStatus.isHasSeed) {
      return nodeStatus;
      // throw new Error('ErrSeedNotExist')
    }
    console.log("accounts", accounts);
    const account =
      accounts &&
      accounts.wallets &&
      accounts.wallets.find((wallet: any) => {
        return wallet.label === "YUAN-miner节点";
      });
    console.log("account", account);
    if (!account) {
      // throw new Error('没有导入钱包')
      return nodeStatus;
    }
    return {
      ...nodeStatus,
      ...{ isBind: true },
      ...{ walletStatus, isSync, ticketCount, accounts, peersInfo },
    };
    // return Object.assign({},{walletStatus,isSync,ticketCount,accounts,peersInfo},{isBind:true},deviceInfo);
  } catch (err) {
    console.log(err.message || err, "-->err");
    if (["ErrAccountNotExist"].includes(err)) {
      return nodeStatus;
    }
    if (
      ["ErrChannelClosed"].includes(err) ||
      ["Failed to fetch"].includes(err.message)
    ) {
      return { err: "节点已关闭", ...{ nodeStatus } };
    }
    return { ...{ err }, ...nodeStatus };
  }
};
