



















import Vue from "vue";
import DetailsCard from "@/views/Details/DetailsCard.vue";
// import DetailsList from '@/views/Details/DetailsList.vue'
import DetailsFooter from "@/views/Details/DetailsFooter.vue";
import { NavBar, Toast } from "vant";
import { TICKET_COUNT, TICKET_ADDRESS } from "@/constance/const";
import service from "@/service/chain33";
import { poolInfo, bindPool, checkBind } from "@/service/pool";
import { signTxGroup } from "@33cn/wallet-api";
export default Vue.extend({
  components: {
    "nav-bar": NavBar,
    DetailsCard,
    // DetailsList,
    DetailsFooter,
  },
  data() {
    return {
      info: {} as any,
      fee: 0,
      profit: 0,
      ticket: 0,
      isBind: false, // 是否初次委托
      ticketNum: TICKET_COUNT, // 每票BTY
      min: undefined as undefined | number,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    getFee() {
      service.getProperFee(0, 0).then((res) => {
        this.fee = res.data.result.properFee;
      });
      service
        .getBalance(this.$store.state.app.address, "coins")
        .then((res: any) => {
          this.profit = Math.floor(res.data.result[0].balance / 1e8);
        });
    },
    getInfo() {
      // Toast.loading({
      //   forbidClick: true,
      //   duration: 0,
      // });
      poolInfo(this.$route.query.id as string).then((res: any) => {
        // Toast.clear();
        this.info = res.data.data;
        checkBind(this.$route.query.id as string)
          .then((res) => {
            // 已经绑定过
            if (res.data.data.isSucc) {
              this.isBind = true;
              this.min = 0;
            }
          })
          .catch(() => {
            this.isBind = false;
            this.min = this.info.minTicket;
          });
      });
    },
    signGroup(txs: Array<string>) {
      return signTxGroup(txs.join(), "none", -1);
    },
    async buy(value: string) {
      if ((this.profit - 1) / this.ticketNum < +(this.min as number)) {
        Toast(
          `您的票数余额为${
            Math.floor((this.profit - 1) / this.ticketNum) < 0
              ? 0
              : Math.floor((this.profit - 1) / this.ticketNum)
          }票，不足${this.min}票`
        );
        return;
      }
      if (Number(value) <= 0) {
        Toast("委托票数必须大于0");
        return;
      }
      /**如果已经绑定过则不用再绑定了 */
      let bindRes: any;
      if (!this.isBind) {
        console.log("bindAddr", this.info.minerAddr);
        console.log("originAddr", this.$store.state.app.address);
        bindRes = await service.createBindMiner({
          bindAddr: this.info.minerAddr,
          originAddr: this.$store.state.app.address,
          amount: this.ticketNum * Number(value) * 1e8,
          checkBalance: false,
        });
        console.log("绑定结果", bindRes);
      }

      console.log("TICKET_ADDRESS", TICKET_ADDRESS);
      const transRes = await service.createTransaction({
        fee: this.fee,
        to: TICKET_ADDRESS,
        amount: this.ticketNum * Number(value) * 1e8,
        note: "coins->ticket",
        execName: "ticket",
      });
      if (!this.isBind) {
        // sign = await this.signGroup([
        //   bindRes.data.result.txHex,
        //   transRes.data.result,
        // ]);
        // const res = await service.createRawTxGroup([
        //   { txs: [bindRes.data.result.txHex, transRes.data.result] },
        // ]);
        service.signGroupTransition(
          `${bindRes.data.result.txHex},${transRes.data.result}`,
          async (sign: string | Object) => {
            if (!sign) {
              Toast("签名不存在");
              return;
            }
            const s = typeof sign === "string" ? JSON.parse(sign) : sign;
            if (s.error) {
              Toast.fail(s.error);
              return;
            }
            this.startTransaction(s.signHash, value, 1);
          }
        );
      } else {
        // sign = await this.signGroup([transRes.data.result]);
        service.signTransition(
          transRes.data.result,
          async (sign: string | Object) => {
            if (!sign) {
              Toast("签名不存在");
              return;
            }
            const s = typeof sign === "string" ? JSON.parse(sign) : sign;
            if (s.error) {
              Toast.fail(s.error);
              return;
            }
            this.startTransaction(s.signHash, value, 0);
          }
        );
      }
    },
    async startTransaction(sign: string, value: string, type: number) {
      Toast.loading({
        duration: 0,
        message: "委托中",
        forbidClick: true,
      });
      console.log("sign", sign);
      const send = await service.sendTransaction(sign);
      if (send.data.error) {
        Toast(send.data.error || "委托失败");
        return;
      }
      let res = {} as any;
      const timer = setInterval(async () => {
        res = await service.getTransaction(send.data.result);
        if (!res.data.error) {
          clearInterval(timer);
          let resResult = res;
          if (type === 1) {
            const next = res.data.result.tx.next;
            const transaction = await service.getTransaction(next);
            resResult = transaction;
          }
          if (resResult.data.result.receipt.ty === 1) {
            Toast(resResult.data.result.receipt.tyName);
          } else {
            const isBind = this.isBind ? true : false;
            // if (isBind) {
            //   Toast.success('委托成功')
            //   return
            // }
            bindPool(this.info.id, value, isBind)
              .then(async (r: any) => {
                if (r.data.data.isSucc) {
                  this.$store.commit("global/SET_BALANCE", this.ticket);
                  Toast.success("委托成功");
                  this.getFee();
                  this.getInfo();
                }
              })
              .catch((e) => {
                Toast(e.response.data.reason);
              });
          }
        }
      }, 1000);
    },
  },
  created() {
    this.getFee();
    this.getInfo();
  },
  beforeDestroy() {
    Toast.clear();
  },
});
