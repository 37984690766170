import { rpc } from '@/service/rpc';
import errMsg from '@33cn/chain33errori18n'
export  async function saveSeedService(seed:string,passwd:string,url= ''):Promise<any>{
    const saveSeedRsp = await rpc.saveSeed(seed,passwd,url)
    console.log('saveSeedRsp',saveSeedRsp);
    if(!saveSeedRsp.isOK) throw errMsg(saveSeedRsp.msg)
    return saveSeedRsp;
}
export async function unLockService(passwd:string,walletorticket = true,timeout = 0,url = ''){
    const unlockRsp = await rpc.unlock(passwd,walletorticket,timeout,url);
    console.log('unlockService->',unlockRsp)
    if(!unlockRsp.isOK) throw  errMsg(unlockRsp.msg);
    return unlockRsp;
}
export async function newAccountService(label= '',url =''){
    const newAccountRsp = await rpc.newAccount(label,url);
    console.log('newAccountService->',newAccountRsp)
    return newAccountRsp;
}

export async function createAccount(config:any,url = ''){
    console.log('createAccount params->',config)
    config = {...{
        walletorticket: false,// 默认钱包全解锁 
        timeout:0 ,// 解锁时间，永久解锁
        label:'' // 账号label
    },...config};
    const { seed, passwd, walletorticket ,timeout ,label} = config
    await saveSeedService(seed,passwd,url);
    await unLockService(passwd,walletorticket,timeout,url);
    await newAccountService(label,url);
}