



































































import Vue from "vue"
import InfoBar from "@/components/InfoBar.vue"
import { TICKET_COUNT } from "@/constance/const"
import ListLine from "@/components/ListLine.vue"
export default Vue.extend({
  components: {
    InfoBar,
    ListLine,
  },
  props: {
    info: {
      type: Object,
    },
  },
  computed: {
    days(): number {
      return +this.info.days - +this.info.runDays
    },
    ticketCount(): number {
      return TICKET_COUNT
    },
    address(): string {
      return (
        this.info?.minerAddr?.slice(0, 10) +
        "..." +
        this.info?.minerAddr?.slice(-10)
      )
    },
  },
})
