






































































import Vue from 'vue'
import { getDeviceList, getWifiName } from '@/utils/bridge'
import { getNodeStatus } from '@/utils/getDeviceInfo';
import DeviceItem from './DeviceItem.vue';
import { APP_NAME } from '@/constance/config';
import { closeNode, resetWallet, escrowPassword } from '@/service/device';
import { passwdInputProxy } from '@/utils/passwdProxy';
import { importSeed } from '@/utils/bridge';
import { createAccount } from '@/utils/account';
import { Toast, Dialog } from 'vant';
export default Vue.extend({
  data() {
    return {
      pageShow: false,
      wifiName: '',
      devices: [] as Array<any>,
      isfeedback: false,
      // btyAddress: ''
    }
  },
  computed: {
    APP_NAME():string {
      return APP_NAME
    },
    btyAddress():string {
      return this.$store.state.app.address
    },
  },
  components: {
    DeviceItem,
  },
  methods: {
    scanDevicesHandler() {
      this.paiList()
    },
    getWifi() {
      getWifiName('', (res: string|{name: string}) => {
        console.log(res)
        if (typeof res === 'string') {
          this.wifiName = JSON.parse(res)?.name
        } else {
          this.wifiName = res?.name
        }
      })
    },
    isEqualBtyAddress(mineAddress: string): boolean {
      return this.btyAddress === mineAddress;
    },
    resetWalletHandler(params: any){
      if (!this.isEqualBtyAddress(params.mineAddress)) {
        this.$toast('请确认当前币钱包地址与YUAN-miner设备地址一致');
        return;
      }
      let toast: any;
      Dialog
        .confirm({
          title: '重置钱包',
          message: '重置钱包将清除设备数据（完成可重新绑定设备）'
        })
        .then(async () => {
          toast = this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: '正在重置钱包，约30s...'
          });
          const resetWalletResult = await passwdInputProxy(resetWallet)(params.ip);
          console.log('resetWalletResult', resetWalletResult);

          setTimeout(() => {
            // this.$router.push({ name: 'index' });
            toast.clear();
          }, 30000);
        })
        .catch(err => {
          console.log('cancel')
          toast.clear();
          if (err !== 'cancel') {
            this.$toast(err);
          }
          console.log('取消重置钱包', err);
        });
    },
    safeCloseHandler(params: any){
      Dialog
        .confirm({
          title: '安全关闭节点',
          message: '确认安全关闭节点？'
        })
        .then(async () => {
          await closeNode(params.ip)
          Dialog
            .alert({
              title: '关闭成功',
              // message: '矿机程序已经下载完成，请手动重启YUAN-miner设备',//请等待YUAN-miner程序重启
              message: '节点关闭成功,请手动关闭YUAN-miner设备',
            })
            .then(() => {
              // on close
              this.$router.replace({name:'home'});
            });
        })
        .catch(err => {
          if (err !== 'cancel') {
            this.$toast(err);
          }
          console.log('安全关闭节点', err);
        });
    },
    feedBackHandler(params: any){
      // if(this.isfeedback){
        Dialog.confirm({
          title:'反馈',
          message:'确定需要反馈此台设备问题？'
        }).then(() => {
          this.$router.push({name:'feedback',params:{ip:params.ip,deviceSerial:params.deviceSerial}})
        }).catch(err => {
          if(err === 'cancel'){
            console.log(err)
          }
        })
      // }
    },
    routeTo(params: any) {
      if (!this.isEqualBtyAddress(params.mineAddress)) {
        this.$toast('请确认当前币钱包地址与YUAN-miner设备地址一致');
        return;
      }
      this.$router.push({
        name: 'miningDetail',
        params: {
          ip: params.ip,
          deviceSerial:params.deviceSerial
        }
      });
    },
    // 从币钱包获取seed和密码，》 保存到树莓派    》 解锁树莓派钱包  》 新建树莓派account
    async linkDevice({ ip,deviceSerial }: any) {
      console.log('linkDeviceip:',ip)
      let toast: any;
        let url = `http://${ip}:8801`;
        importSeed('', async (seedAndPasswd: any) => {
          try {
            if (typeof seedAndPasswd === 'string') {
              seedAndPasswd = JSON.parse(seedAndPasswd)
            }
            console.log('seedandPasswd', seedAndPasswd);
            const { seed, passwd } = seedAndPasswd;
            toast = this.$toast.loading({
              duration: 0,
              forbidClick: true,
              message: '正在导入钱包，请稍后...'
            });
            const label = 'YUAN-miner节点';
            await createAccount({
              seed,
              passwd,
              label
            },url);
            const savePasswd = await escrowPassword(seedAndPasswd.passwd, ip);
            console.log('savePasswd', savePasswd);
            // const applyAirDropResult = await applyAirDrop(deviceSerial,this.btyAddress);
            // console.log('applyAirDropResult',applyAirDropResult);
            toast.clear();
            this.$toast.success('导入成功');
            this.$router.push({ name: 'miningDetail', params: { ip ,deviceSerial}});
          } catch (err: any) {
            toast.clear();
            console.log('导入出错啦', err);
            this.$toast(err);
          }
        });
    },
    paiList(): any {
      getDeviceList('', async(v: any) => {
        Toast.loading({
          duration: 0,
        })
        console.log('deviceList', v)
        if (typeof v === 'string') {
          v = JSON.parse(v)
        }
        let tempDeviceList = [] as any
        for (let i = 0; i < v.length; i++) {
          let deviceData = await getNodeStatus(v[i])
          tempDeviceList.push(deviceData)
        }
        console.log('tempDeviceList', tempDeviceList)
        this.devices = tempDeviceList
        Toast.clear()
      })
    },
  },
  mounted() {
    this.getWifi()
    this.paiList()
    console.log(1)
  }
})
