

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Dialog, RadioGroup, Radio, CellGroup, Cell } from 'vant';
import { Prop, PropSync } from 'vue-property-decorator';
// import getNodeStatus from '../mixins/getNodeStatus';
let activeIcon = require('@/assets/image/activeIcon@2x.png');
let inactiveIcon = require('@/assets/image/inactiveIcon@2x.png');

@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    'van-radio-group': RadioGroup,
    'van-cell-group': CellGroup,
    'van-cell': Cell,
    'van-radio': Radio
  }
})
export default class LockStatusDialog extends Vue {
  @PropSync('showLockStatusDialog', { type: Boolean }) show!: boolean;

  @Prop({
    type: Object,
    required: true
  })
  currentLockStatus!: any;

  radio = '2';

  activeIcon = activeIcon;
  inactiveIcon = inactiveIcon;

  //是否显示取消按钮 -- 钱包全锁定时不显示
  get showCancel() {
    const {isWalletLock,isTicketLock} = this.currentLockStatus;
    return !(isWalletLock && isTicketLock);
  }
  openHandler() {
    const {isWalletLock} = this.currentLockStatus;
    this.radio = isWalletLock?'2':'1'
    console.log('radio', this.radio);
  }
  confirmHandler() {
    this.show = false;
    this.$emit('showPasswordDialog', this.radio);
  }
}
