











































import Vue from 'vue';

export default Vue.extend({
  props: {
    isIcon: {
      type: Boolean,
      default: false
    },
    walletStatus: {
      type: Object as any,
      required: true
    },
    timeSync: {
      type: Object as any,
    },
    blockSync: {
      type: Boolean,
      required: true
    },
    peerHeight: {
      type: Number,
    },
    allHeight: {
      type: Number
    }
  },
  computed: {
    isButton(){
      return this.isIcon?'':'_button'
    },
    iconClass(){
      return {
        icon:this.isIcon
      }
    }
  },
  methods: {
    lockStatusHandler() {
      this.$emit('showLockStatusDialog')
    },
    autoMiningStatusHandler() {
      this.$emit('autoMiningHandler')
    },
    showHeightHandler() {
      this.$emit('showHeightHandler')
    }
  }
})
