




























































import Vue from "vue"
import { NavBar, Picker, Popup } from "vant"
import { getInviteList } from "@/service/pool"
import RecommendItem from "./RecommendItem.vue"
import dayjs from "dayjs"

type RecommendInfo = {
  list: { id: string; addr: string; tickets: number; bindAt: string }[]
  count: string
  tickets: number
}

export default Vue.extend({
  components: {
    NavBar,
    RecommendItem,
    // "van-icon": Icon,
    "van-picker": Picker,
    "van-popup": Popup,
  },
  data() {
    return {
      showPicker: false,
      columns: [
        {
          label: "1月",
          value: "1",
        },
        {
          label: "2月",
          value: "2",
        },
        {
          label: "3月",
          value: "3",
        },
        {
          label: "4月",
          value: "4",
        },
        {
          label: "5月",
          value: "5",
        },
        {
          label: "6月",
          value: "6",
        },
        {
          label: "7月",
          value: "7",
        },
        {
          label: "8月",
          value: "8",
        },
        {
          label: "9月",
          value: "9",
        },
        {
          label: "10月",
          value: "10",
        },
        {
          label: "11月",
          value: "11",
        },
        {
          label: "12月",
          value: "12",
        },
      ],
      recommendInfo: {} as RecommendInfo,
      pagination: {
        page: 1,
        pageSize: 100,
        // month: dayjs(Date.now()).format("MM"),
      },
    }
  },
  computed: {
    nowYear() {
      return dayjs(Date.now()).format("YYYY")
    },
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onConfirm() {
      //   this.pagination.month = val.value
      this.pagination.page = 1
      this.getRecommend()
    },
    onCancel() {
      this.showPicker = false
    },
    getRecommend() {
      getInviteList(this.pagination).then((res) => {
        // this.recommendInfo = {
        //   list: [
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //     { id: "234", addr: "324", tickets: 234, bindAt: "1658909903" },
        //   ],
        //   count: "234",
        //   tickets: 123,
        // }
        this.recommendInfo = res.data.data
      })
    },
  },
  created() {
    this.getRecommend()
  },
})
