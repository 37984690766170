



































import Vue from 'vue'
import RecordItem from './RecordItem.vue'
import { NavBar, Icon, Picker, Popup } from 'vant';
import { getMinerData } from '../../service/airdrop';
import dayjs from 'dayjs';

export default Vue.extend({
  components: {
    RecordItem,
    NavBar,
    'van-icon': Icon,
    'van-picker': Picker,
    'van-popup': Popup
  },
  data() {
    return {
      showPicker: false,
      columns: [
        {
          label: '1月',
          value: '1'
        },
        {
          label: '2月',
          value: '2'
        },
        {
          label: '3月',
          value: '3'
        },
        {
          label: '4月',
          value: '4'
        },
        {
          label: '5月',
          value: '5'
        },
        {
          label: '6月',
          value: '6'
        },
        {
          label: '7月',
          value: '7'
        },
        {
          label: '8月',
          value: '8'
        },
        {
          label: '9月',
          value: '9'
        },
        {
          label: '10月',
          value: '10'
        },
        {
          label: '11月',
          value: '11'
        },
        {
          label: '12月',
          value: '12'
        },
      ],
      amount: '',
      airList: [] as any,
      pagination: {
        page: 1,
        pageSize: 100,
        month: dayjs(Date.now()).format('MM')
      }
    }
  },
  computed: {
    nowYear() {
      return dayjs(Date.now()).format('YYYY')
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onConfirm(val: {value: string}) {
      this.pagination.month = val.value
      this.pagination.page = 1
      this.getMiner()
    },
    onCancel() {
      this.showPicker = false
    },
    getMiner() {
      getMinerData(this.pagination).then(res => {
        this.amount = res.data.amount
        this.airList = res.data.list
        if (this.showPicker) this.showPicker = false
      })
    }
  },
  created() {
    this.getMiner()
  }
})
