













import Vue from 'vue'
import dayjs from 'dayjs'
export default Vue.extend({
  props: {
    info: {
      type: Object
    }
  },
  computed: {
    timer() {
      const time = +((this.info as any).minedTime + '000')
      return dayjs(time).format('MM-DD HH:mm:ss')
    },
    hash() {
      const _hash = this.info.hash
      return _hash.slice(0, 8) + '...' + _hash.slice(_hash.length - 8)
    }
  }
})
